@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700&family=Spartan:wght@300;400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --nav-green: #009900;
  --drk-green: #388e3c;
  --lit-green: #82bd86da;

  --agc-green-1: #2F9747;
    --agc-green-2: #3DA146;
    --agc-green-3: #56B343;
    --agc-green-4: #82D23D;
    --agc-green-5: #95DF3B;
    --agc-orange: #F7941D;
    
  --trans: all 0.25s ease-in-out;
}
.bg-green-1{ background-color: var(--agc-green-1);}
.bg-green-2{ background-color: var(--agc-green-2);}
.bg-green-3{ background-color: var(--agc-green-3);}
.bg-green-4{ background-color: var(--agc-green-4);}
.bg-green-5{ background-color: var(--agc-green-5);}
.bg-orange{ background-color: var(--agc-orange);}

.dark-navbar{
  background-color: var(--agc-green-1);
}
.top-navbar{
  padding: 10px !important;
  border-bottom: 1px solid #fcfcfc33;
}
.fixed .cs-logo .sticky-logo{
  display: none !important;
}
.sticky .cs-logo .main-logo{
  display: none !important;
}
.fixed svg.grn-txt{
  color: #ffffff !important;
}
.sticky svg.grn-txt{
  color: var(--agc-green-1) !important;
}
.solid-bg{
  margin: 0;
  padding: 30px 50px 0;
}
.fixed .main-nav > div > li > a:hover {
 color:  var(--agc-green-4) !important;
}
.fixed .main-nav > div > li > a.active {
  color: var(--agc-green-4) !important;
    font-weight: 600;
}
.sticky .main-nav > div > li > a {
  color:  #292929 !important;
 }
.sticky .main-nav > div > li > a:hover {
  color:  var(--agc-green-4) !important;
 }
.sticky .main-nav > div > li > a.active {
   color:  var(--agc-green-4) !important;
 }
.mid-container-wrap{
  width: 100%;
    display: flex;
    justify-content: flex-end;
}
.mid-container-wrap ul{
  display: flex;
    width: 100%;
    margin-right: 40px;
    justify-content: flex-end;
}
.mid-container-wrap ul > div, .mid-container-wrap ul > * {
  display: inline-block;
}

@layer components {
  .grn-txt {
    @apply text-[#009900];
  }

  .grn-text {
    @apply text-[#3dd800];
  }

  .grn-bg {
    @apply bg-[#3dd800];
  }

  .left-sidebar-item {
    @apply flex py-4 pl-2 w-full hover:bg-gray-300 transition-all text-black hover:font-bold;
  }

  .left-sidebar-text {
    @apply mb-0 text-black group-hover:font-bold group-hover:ml-1.5 group-hover:text-[#3dd800] transition-all !important;
  }
}

/* .left-sidebar-item:hover {
	font-weight: bold !important;
	background: red !important;
} */

.container {
  width: 100%;
  @apply mx-auto;
  @apply px-5;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
.home-card{
  width: 280px !important;
  margin: 0 0 40px !important;
  padding: 10px 15px !important;
  opacity: 1 !important;
  display: flex;
}
.home-card .item-icon {
    width: 60px !important;
    height: 60px !important;
    border-radius: 50% !important;
    margin: 0 7px 0 0!important;
    display: flex;
}
.home-card .item-icon img{
  width: 2.4rem;
  height: 2.4rem
}
.home-card p{
  font-size: 14px;
  width: calc(100% - 80px);
}
.w-full {
  width: 100%;
  z-index: 1;
  position: relative;
}
.hero-header{
    position: relative;
    padding: 200px 0 100px;
    margin-top: -132px;
    z-index: 0;
    background-size:cover;
    background-attachment: fixed;
}
.hero-header .page-title{
  margin: 40px auto;
  width: 80%;
}
.hero-header .page-title .title{
  font-size: 60px;
  font-weight: 600;
  margin-bottom: 30px;
}
.hero-header .page-title .description{
  font-size: 18px;
}
.page-breadcrumbs, .breadcrumbs{
  position: relative;
  display: flex;
  width: 100%;
}
.page-breadcrumbs .breadcrumbs ul{
  width: 100%;
  list-style: none;
}
.page-breadcrumbs .breadcrumbs ul li{
  display: inline-block;
  margin-right: 10px;
  font-size: 18px;
}
.page-breadcrumbs .breadcrumbs ul li:after{
  content: "/";
  font-size: 22px;
  position: relative;
  color: #ffffff;
  padding: 0 5px 0 15px;
}
.page-breadcrumbs .breadcrumbs ul li:last-child:after{
  display:none;
}
.page-breadcrumbs .breadcrumbs ul li a,
.page-breadcrumbs .breadcrumbs ul li a:visited {
  color: #ffffff;
  transition: all ease-in .2s;
}
.page-breadcrumbs .breadcrumbs ul li a{
  opacity: 0.75;
  transition: all ease-in .2s;
}
.page-breadcrumbs .breadcrumbs ul li:hover a{
  opacity: 1;
  color: #ffffff;
  transition: all ease-in .2s;
}
.page-breadcrumbs .breadcrumbs ul li.active a{
  opacity: 1;
  transition: all ease-in .2s;
}
.page-breadcrumbs .breadcrumbs ul li.active:hover a{
  color: var(--agc-green-4);
  transition: all ease-in .2s;
}
.text-center, .text-center *{
  text-align: center !important;
}
.text-white{
  color: #ffffff !important;
}
.nav-bg {
  background-color: #3dd800;
}
.section-title{
  font-size: 45px;
}
.section-subtitle{
  font-size: 18px;
  text-transform: uppercase;
  color:rgba(0, 0, 0, 0.4)
}
.bg-grey{
  background: #f8f9fa;
}
.bg-grey-2{
  background: #f3f4f5;
}
.grn-text {
  color: #3dd800;
}
.md-offset-1{
  margin-left: 8.333%;
}
.nav-bg-dark {
  background-color: #009900;
}
.list-block h4{
  font-size: 24px;
  margin: 10px 0  20px;
  font-weight:600;
}
.list-block ul {
  margin-left: 20px;
}
.list-block ul > li{
  display: block;
  margin-right: 30px;
  vertical-align: top;
  position: relative;
  margin-bottom: 7px;
  font-weight:600;
}
.list-block ul > li:before{
  content: "→";
  color: var(--agc-green-3);
  position: absolute; 
  top: 0;
  left: -20px;
}
.list-block ul > li > ul {
   transition: all ease-in .3s;
}

.list-block ul > li:hover > ul {
}
.list-block ul > li > ul > li {
  display: block;
  font-weight: 400;
}
.service-item{
  padding: 30px;
  box-shadow: 5px 5px 35px rgb(34 76 14 / 15%);
  width: 90%;
  background: #ffffff;
  border-radius: 6px;
  transition: all ease-in .3s;
}
.service-item:hover{
  box-shadow: 0 0 20px rgb(34 76 14 / 55%);
  transition: all ease-in .3s;
}
input:not([type=range]), label, select, summary, textarea{
  padding-left: 10px;
  padding-right: 10px;
}

.orng {
  color: #f7941d;
}

.sec-bg {
  background-color: #0c460f;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0;
}

* {
  box-sizing: border-box;
  /* font-family: "Spartan", sans-serif; */
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

input::placeholder {
  font-weight: 100;
}

button:disabled {
  cursor: not-allowed;
  background-color: #528f52;
}

.flexed {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-btw {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.posts-wrapper {
  min-height: 300px;
}

.form-field__input {
  border: 0.8px solid rgba(6, 211, 6, 0.842);
  padding: 7px 10px !important;
  border-radius: 3px !important;
  outline: 2px solid transparent;
  width: 100%;
  transition: all 200ms ease-in-out;
}

label.ant-form-item-required,
.form-label {
  margin-bottom: 1px !important;
  color: black !important;
  font-weight: 500 !important;
  letter-spacing: 0.025em !important;
}

.form-field__input:hover,
.form-field__input:active {
  border: 0.8px solid #ea580c !important;
  box-shadow: none !important;
  outline-width: 0 !important;
}

.form-field__input__2 {
  border: 0.8px solid rgba(0, 0, 0, 0.4);
  padding: 7px 10px !important;
  border-radius: 3px !important;
  outline: 2px solid transparent;
  width: 100%;
  transition: all 200ms ease-in-out;
}

.form-field__input__2:hover,
.form-field__input__2:active {
  border: 0.8px solid rgba(0, 0, 0, 0.8) !important;
  box-shadow: none !important;
  outline-width: 0 !important;
}

.form-field__input__2:disabled {
  border: 0.8px solid #e2e2e2;
  background: #ffffff;
  color: #000000;
}

.react-tel-input .form-control {
  position: relative !important;
  font-size: 14px !important;
  letter-spacing: .01rem !important;
  padding-left: 48px !important;
  margin-left: 0 !important;
  line-height: 25px !important;
  height: 36px !important;
  width: 100% !important;
  border: 0.8px solid rgba(6, 211, 6, 0.842) !important;
  border-radius: 3px !important;
  outline: 2px solid transparent !important;
  transition: all 200ms ease-in-out !important;
}

.react-tel-input .flag-dropdown {
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  padding: 0 !important;
  background-color: #f5f5f5 !important;
  border: 0.8px solid rgba(6, 211, 6, 0.842) !important;
  border-radius: 3px 0 0 3px !important;
}

.rdw-editor-main{
  border: 0.8px solid rgba(0, 0, 0, 0.8) !important;
  padding: 10px 10px 70px 10px;
}

.rdw-editor-toolbar{
  border: 0.8px solid rgba(0, 0, 0, 0.8) !important;
}


.rti--tag{
  background-color: #3dd800 !important;
  color: #FFFFFF !important;
  font-weight: bold !important;
}

.form-field__input__2:disabled:hover {
  border: 0.8px solid #e2e2e2 !important;
}

.farmerList thead tr th,
.aggregationList thead tr th {
  font-weight: 700;
}

.farmerList thead tr th,
.blogList thead tr th {
  font-weight: 700;
}

.modal-div {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* #333333 background with opacity */
  z-index: 1110; /* Specify a stack order in case you're using a different order for other elements */
}

.modal-box-container {
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.table-popup{
  padding: 24px;
  gap: 40px;
  position: relative;
  width: 22%;
  background: #FFFFFF;
  border-radius: 12px;
}

.table-row-light {
  background-color: #ffffff;
}

.table-row-dark {
  background-color: #fbfbfb;
}

.style_lg_active {
  background: #f7941c;
}

.style_lg_active .left-sidebar-text {
  color: #ffffff !important;
  @apply font-bold;
}

.style_lg_active .left-sidebar-icon {
  @apply text-white;
}

.phone_input {
  padding: 2px 10px !important;
}

.mb-24{
  margin-bottom: 24px !important;
}

.hidden-form .ant-form-item-control-input {
  display: none;
}

.home-card {
  background: #ffffff;
  width: 280px;
  border-radius: 6px;
  padding: 10px 20px;
  margin: 0 0 58px;
  box-shadow: 0 0 15px 2px rgb(0 0 0 / 18%);
  opacity: 0.9;
  transition: all ease-in 0.2s;
  cursor: pointer;
}

.home-card:hover {
  box-shadow: 0 0 5px 2px rgb(0 0 0 / 18%);
}

.item-left {
  background-color: #fff0de;
}

.item-right {
  background-color: #edffe6;
}

.item-icon {
  width: 70px;
  height: 70px;
  border-radius: 70px;
  line-height: 50px;
  margin: 0 15px 0 0;
  text-align: center;
  vertical-align: middle;
}

.ant-steps-item-process .ant-steps-item-icon {
  background-color: #3dd800 !important;
  border-color: #3dd800 !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
  border-color: #3dd800 !important;
}

.ant-steps-item-icon .ant-steps-icon svg {
  color: #3dd800;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title:after {
  background-color: #3dd800 !important;
}

.steps-content {
  margin-top: 30px;
}

.steps-action {
  margin-top: 24px;
}

.dues {
  padding: 6px 10px !important;
  font-size: 12px;
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: #3dd800;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #3dd800;
  border-color: #3dd800;
}

.due-form {
  margin: 0;
}

@media (max-width: 760px) {
  .item-icon {
    width: 35px;
    height: 35px;
  }

  .home-card {
    padding: 10px 10px;
    width: 180px;
  }
}

@media (max-width: 375px) {
  .item-icon {
    width: 25px;
    height: 25px;
  }

  .home-card {
    padding: 10px 10px;
    width: 135px;
  }
}


/* Edit Association  Style */

/* Chrome, Safari, Edge, Opera */
.webkit-disabled::-webkit-outer-spin-button,
.webkit-disabled::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.webkit-disabled[type=number] {
  -moz-appearance: textfield;
}

/* Edit cooperative Styles */
.btn-primary{
  background-color:rgb(61, 215, 0) !important;
  color:white;
}
.btn-primary:hover{
  background-color:rgb(40, 129, 4) !important;
}

.form-control{
  font-size:14px!important;
}


@media (max-width: 991px){
	.solid-bg {
		margin: 0;
		padding: 30px 15px 0;
	}
	.solid-bg.bg-green-1 > .w-full.bg-red{
		margin-bottom: 20px;
	}
	.ljREWP {
		padding-left: 0;
		padding-right: 0;
	}
	.iqubZW {
		padding-top: 150px;
	}
	.iqubZW > div {
		margin-bottom: 0px;
		display: -webkit-inline-box;
	}
	.home-card {
		display: flex;
		padding: 10px!important;
		width: 50% !important;
	}
	.home-card .item-icon {
		height: 50px!important;
		width: 50px!important;
	}
	.home-card .item-icon img {
		height: 2.2rem;
		width: 2.2rem;
	}
	.home-card p {
		font-size: 14px;
		color: #7e7e7e;
		line-height: 1.2;
		width: calc(100% - 55px);
	}
}
@media (max-width: 768px){
	.iqubZW {
		padding-top: 165px;
	}
	.home-card .item-icon {
		height: 45px!important;
		width: 45px!important;
	}
	.home-card .item-icon img {
		height: 2.1rem;
		width: 2.1rem;
	}
	.home-card p {
		font-size: 13px;
		width: calc(100% - 50px);
	}
}

@media (max-width: 576px){
	.iqubZW {
		padding-top: 180px;
	}
	.home-card {
		display: flex;
		margin: 0 0 40px!important;
		opacity: 1!important;
		padding: 10px!important;
		width: 50% !important;
	}
	.home-card .item-icon {
		height: 35px!important;
		width: 35px!important;
	}
	.home-card .item-icon img {
		height: 1.75rem;
		width: 1.75rem;
	}
	.home-card p {
		font-size: 12px;
		width: calc(100% - 40px);
	}
}
